import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

type UserFields = 'login' | 'password';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public signup: boolean;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;

  // public email = 'admin@mail.com';
  // public password = '1111';

  public email = '';
  public password = '';

  public errors = {
    login_required: false,
    password_required: false,
  }

  constructor(private route: Router, public http:HttpClient) {
  }

  ngOnInit() {
  }

  signin(){
    this.http.post<any>('https://wasali.cherish.com.ua/dash/login',{email:this.email, password:this.password}).subscribe(res => {
      if(res.error_code == 0){
        localStorage.setItem('admin', JSON.stringify(res.data.admin));
        localStorage.setItem('token', res.data.admin.token);
        this.route.navigateByUrl('/trips');
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'User not found!'
        })
      }
    });
  }

}
