import { Routes } from '@angular/router';

export const content: Routes = [
	{
		path: 'admins',
		loadChildren:() => import('../../components/admins/admins.module').then(m => m.AdminsModule)
	},
	{
		path: 'createadmin',
		loadChildren:() => import('../../components/createadmin/createadmin.module').then(m => m.CreateadminModule)
	},
	{
		path: 'editadmin/:id',
		loadChildren:() => import('../../components/editadmin/editadmin.module').then(m => m.EditadminModule)
	},
	{
		path: 'regions',
		loadChildren:() => import('../../components/regions/regions.module').then(m => m.RegionsModule)
	},
	{
		path: 'news',
		loadChildren:() => import('../../components/news/news.module').then(m => m.NewsModule)
	},
	{
		path: 'createnew',
		loadChildren:() => import('../../components/createnew/createnew.module').then(m => m.CreatenewModule)
	},
	{
		path: 'editnew/:id',
		loadChildren:() => import('../../components/editnew/editnew.module').then(m => m.EditnewModule)
	},
	{
		path: 'content',
		loadChildren:() => import('../../components/content/content.module').then(m => m.ContentModule)
	},
	{
		path: 'editcontent/:id',
		loadChildren:() => import('../../components/editcontent/editcontent.module').then(m => m.EditcontentModule)
	},
	{
		path: 'users',
		loadChildren:() => import('../../components/users/users.module').then(m => m.UsersModule)
	},
	{
		path: 'requests',
		loadChildren:() => import('../../components/requests/requests.module').then(m => m.RequestsModule)
	},
	//////////////////////////,
	{
		path: 'calls',
		loadChildren:() => import('../../components/calls/calls.module').then(m => m.CallsModule)
	},
	{
		path: 'phones',
		loadChildren:() => import('../../components/phones/phones.module').then(m => m.PhonesModule)
	},
	{
		path: 'langs',
		loadChildren:() => import('../../components/langs/langs.module').then(m => m.LangsModule)
	},
	{
		path:'tarifs',
		loadChildren:() => import('../../components/tarifs/tarifs.module').then(m => m.TarifsModule)
	},
	{
		path:'settings',
		loadChildren:() => import('../../components/settings/settings.module').then(m => m.SettingsModule)
	},
	{
		path:'bug',
		loadChildren:() => import('../../components/bug/bug.module').then(m => m.BugModule)
	},
	{
		path:'codes',
		loadChildren:() => import('../../components/codes/codes.module').then(m => m.CodesModule)
	},
	{
		path:'intervals',
		loadChildren:() => import('../../components/intervals/intervals.module').then(m => m.IntervalsModule)
	}

	///////////////////////////

	,{
		path:'drivers',
		loadChildren:() => import('../../components/drivers/drivers.module').then(m => m.DriversModule)
	},
	{
		path:'clients',
		loadChildren:() => import('../../components/clients/clients.module').then(m => m.ClientsModule)
	},
	{
		path:'documents',
		loadChildren:() => import('../../components/documents/documents.module').then(m => m.DocumentsModule)
	},
	{
		path:'pricing',
		loadChildren:() => import('../../components/pricing/pricing.module').then(m => m.PricingModule)
	},
	{
		path:'bank',
		loadChildren:() => import('../../components/bank/bank.module').then(m => m.BankModule)
	},
	{
		path:'notes',
		loadChildren:() => import('../../components/notes/notes.module').then(m => m.NotesModule)
	},
	{
		path:'coupons',
		loadChildren:() => import('../../components/coupons/coupons.module').then(m => m.CouponsModule)
	},
	{
		path:'trips',
		loadChildren:() => import('../../components/trips/trips.module').then(m => m.TripsModule)
	},
	{
		path:'submissions',
		loadChildren:() => import('../../components/submissions/submissions.module').then(m => m.SubmissionsModule)
	},
	{
		path: 'languages',
		loadChildren:() => import('../../components/languages/languages.module').then(m => m.LanguagesModule)
	}
];
